@import 'styles/mixins/breakpoints';

.toggleBtn {
  margin-top: 20px;
  cursor: pointer;
  display: flex;
  column-gap: 10px;
  align-items: center;
  color: #303030;
  border: none;
  background: none;
  padding: 0;

  &__text {
    font-size: 12px;
    line-height: 1.5;

    @include md {
      font-size: 14px;
    }
  }

  &__icon {
    font-size: 16px;
  }
}

.marketsTitle {
  font-size: 12px;
  line-height: 20px;
  color: #303030;
  font-weight: 600 !important;
  margin: 15px 0;
}

.marketsList {
  list-style-type: decimal;
  padding-left: 18px;
  margin: 15px 0;
  column-gap: 35px;

  &__2columns {
    columns: 2;
  }

  &__mobile {
    columns: 1;
  }

  &:last-of-type {
    margin-bottom: 0;
  }
}

.market {
  font-size: 12px;
  line-height: 20px;
  color: #303030;
}
