.bet {
  position: relative;
  background-color: #ffffff;
  padding: 20px 16px 16px 16px;
  display: flex;
  flex-direction: column;
  gap: 12px;

  &:before {
    position: absolute;
    content: "";
    width: 100%;
    height: 6px;
    top: 0;
    left: 0;
    background-color: #a6d8ff;
  }

  &__lay {
    &:before {
      background-color: #FAC9D1;
    }
  }

  &__top {
    display: flex;
    gap: 16px;
    &__left {
      flex: 1;
      display: flex;
      gap: 4px;
      flex-direction: column;
    }
    &__right {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }
  }

  &__selection {
    display: flex;
    gap: 8px;
    align-items: center;
    &__icon {
      font-size: 18px;
      color: #999999;
      &__positive {
        color: #04a04a;
      }
      &__negative {
        color: #FE3629;
      }
    }
    &__name {
      font-weight: 700 !important;
      font-size: 16px;
      line-height: 24px;
      color: #303030;
    }
  }

  &__ew,
  &__market,
  &__event {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
  }

  &__ew {
    color: #666666;
  }

  &__odds {
    font-weight: 700 !important;
    font-size: 16px;
    line-height: 24px;
    color: #303030;
  }

  &__type {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #666666;
  }

  &__stake,
  &__status {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #666666;
    margin-right: 8px;
  }

  &__value {
    font-weight: 700 !important;
    font-size: 16px;
    line-height: 24px;
    color: #303030;
    display: inline-block;
    &__positive {
      color: #04A04A;
    }
    &__negative {
      color: #FE3629;
    }
  }

  &__middle {
    background-color: #f5f5f5;
    border-radius: 4px;
    padding: 8px 16px 10px 16px;
    display: flex;
    gap: 12px;
  }

  &__stake,
  &__result {
    flex: 1;
  }

  &__bottom {
    color: #999999;
    font-size: 14px;
    line-height: 20px;
  }
}

.column {
  flex-direction: column;
  display: flex;
}