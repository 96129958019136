.name {
  padding-left: 10px;
  padding-right: 10px;
  text-align: center;
  white-space: nowrap;

  &__hasRules {
    & > div {
      padding-right: 0;
      margin-left: -15px;
      display: inline-block;
      vertical-align: middle;
    }
  }

  &__value {
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    box-sizing: border-box;

    &__withRules {
      margin-left: 0 !important;
      padding-right: 20px !important;
    }
  }
}
