.singleLineHeader {
  text-align: right;
  border-left: solid 1px #c0c0c0;
  border-right: solid 1px #c0c0c0;
  &__col {
    display: inline-block;
    border-left: solid 1px #c0c0c0;
    text-align: center;
    height: 28px;
    line-height: 28px;
    vertical-align: top;
    box-sizing: border-box;
  }
  &__fullTime {
    width: 440px;
  }
  &__firstHalf {
    width: 220px;
  }
  &__streaming {
    width: 49px;
  }
}
