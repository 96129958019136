@import 'styles/variables/variables';

.event {
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 12px 16px 24px;
  border-top: 2px solid #666666;

  background-color: #fff;

  &__inPlay {
    position: relative;
    border-top: 0;

    &:before {
      position: absolute;
      content: '';
      width: 100%;
      height: 2px;
      top: 0;
      left: 0;
      background-color: #04a04a;
    }
  }

  &__header {
    display: flex;
    align-items: center;
    gap: 12px;
  }

  &__scoreContainer {
    height: 48px;
    border: 1px solid #ddd;
    border-radius: 4px;
    max-width: 70px;
    width: 70px;
    font-size: 12px;
    box-sizing: border-box;
    overflow: hidden;

    &__inPlay {
      border-color: transparent;
    }
  }

  &__participants {
    height: 100%;
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    & > * {
      flex: 1 1 50%;
      line-height: 24px;
      font-weight: 700 !important;
      font-size: 14px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &__pl {
    flex: 0 1 24px;
    width: 24px;
  }

  &__name {
    flex: 1 1 auto;
    font-weight: 700 !important;
    font-size: 14px;
    line-height: 22px;
  }
}

:global {
  #biab_body.biab_mobileView .biab_mobile-bet-list-open-tab .biab_inplay-sports-container .biab_market-inplay-cell {
    font-size: 14px !important;
    .biab_in-play {
      font-size: 14px !important;
      .biab_basketball,
      .biab_basketball .biab_score,
      .biab_basketball.biab_end,
      .biab_basketball.biab_end .biab_score,
      .biab_soccer,
      .biab_soccer .biab_score,
      .biab_tennis,
      .biab_tennis .biab_tennis-sets,
      .biab_tennis .biab_tennis-games {
        font-size: 14px !important;
      }
    }
  }
  #biab_body.biab_mobileView .biab_mobile-bet-list-open-tab .biab_inplay-sports-container .biab_market-inplay-cell .biab_time-label {
    font-size: 12px;
  }
}
