.unmatchedActions {
  display: flex;
  align-items: center;
  gap: 12px;

  &__container {
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-bottom: 12px;
  }

  &__progressContainer {
    height: 42px;
  }

  &__actionBtn {
    height: 42px;
    gap: 10px;
    font-size: 14px;

    &__icon {
      padding-top: 2px;
      font-size: 24px;
      color: inherit !important;

      &::before {
        color: inherit !important;
      }
    }
  }
}
